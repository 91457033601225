body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  padding: 0 3%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-size: 21px 21px;
  background-position: 0 0, 10px 10px;
  background-color: "#fcfcfc";
  background-image: linear-gradient(
      45deg,
      #efefef 25%,
      transparent 25%,
      transparent 75%,
      #efefef 75%,
      #efefef
    ),
    linear-gradient(
      45deg,
      #efefef 25%,
      transparent 25%,
      transparent 75%,
      #efefef 75%,
      #efefef
    );
}

header {
  position: relative;
  text-align: left;
  background: white;
  margin: 0 calc(-3% - 2px);
  padding: 5% 3% 3%;
  border-bottom: 2px solid #ccc;
  box-shadow: 2px 2px 21px #ccc;
}

h1 {
  line-height: 1.8;
}

.list {
  --card-size: 240px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--card-size));
  justify-content: space-around;
}

.list.small {
  --card-size: 200px;
}

.list.large {
  --card-size: 290px;
}

@media (min-width: 640px) {
  .list.large {
    --card-size: 350px;
  }
}

.list li:focus-within,
.list li:hover {
  z-index: 100;
}

.list hr {
  width: 70%;
  grid-column: 1 / -1;
  margin: 2rem auto;
}

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
}

/* flip the pane when hovered */
.flip-container:hover .flipper,
.flip-container.hover .flipper,
.flip-container:focus .flipper,
.flip-container:focus-within .flipper {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: var(--card-size);
  height: var(--card-size);
}

.front figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: 100%;
}

figcaption {
  line-height: 1;
  padding-top: 0.3rem;
}

figcaption span {
  font-size: 84%
}

.front img {
  max-width: calc(100% - 40px);
  max-height: calc(var(--card-size) - 50px);
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  display: flex;
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
  width: 130%;
  margin-left: -15%;
  position: relative;
}

.back > div {
  width: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.85);
  background-size: cover;
  background-blend-mode: color;
  padding-bottom: 1rem;
  background-position: 50% 50%;
  min-height: var(--card-size);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.back ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.back ul li {
  width: 85%;
  margin: 0 auto 7px;
}

footer {
  padding: 7rem 0;
  text-align: center;
  font-size: 85%;
  font-style: italic;
}

a,
a:hover,
a:visited,
a:active {
  color: #000;
}

.colors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  margin: 0.2rem 15% 0;
}

.colors > div {
  text-align: left;
}

.color {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  vertical-align: midde;
}

nav {
  max-width: 100%;
  padding: 1rem 3% 1rem 0;
  margin: auto;
}

nav strong,
nav label {
  font-weight: bold;
  display: inline-block;
  min-width: 6em;
}

button,
.filters,
.select-menu,
.select-button {
  text-align: center;
  padding: 9px 15px;
  border-radius: 3px;
  border: 2px solid #ccc;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
}

.select-button {
  text-align: left;
}

.select-button::after {
  text-align: center;
}

button,
.filters {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 3px;
  margin-bottom: 3px;
  height: 35px;
  display: inline-flex;
  vertical-align: middle;
}

.filters {
  width: 500px;
  text-align: left;
  max-width: calc(100vw - 50px);
}

.filters label {
  width: 100%;
}

.filters input {
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-left: 3px;
}

button:disabled,
button:disabled:hover {
  background: #eee;
  cursor: default;
}
button:disabled span {
  color: #aaa;
}

button.square::before {
  content: "";
  display: inline-block;
  border: 1px solid #aaa;
  height: 10px;
  width: 10px;
  margin-right: 3px;
  vertical-align: bottom;
  background: currentColor;
}

button.selected {
  background: white;
  border-color: #666;
}

button.selected span {
  color: black;
}

button:hover {
  background: rgba(255, 255, 255, 0.8);
}

button.flat {
  border-color: transparent;
  background: transparent;
  display: block;
  margin: auto;
}

button.flat:hover {
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
}

button.hidden {
  visibility: hidden;
}

button.no-square::before {
  display: none;
}

button span {
  color: black;
}

nav div > *:first-of-type {
  margin-left: 0;
}

button-primary:active,
button-primary.active {
  box-shadow: none;
}

.flag-header {
  display: flex;
  justify-content: left;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  margin-bottom: auto;
}

.flag-map {
  margin: 10px;
  font-size: 70%;
  mix-blend-mode: luminosity;
}

.flag-map img {
  filter: drop-shadow(1px 1px 3px #ccc);
  max-width: 60px;
  max-height: 60px;
}

.flag-title {
  margin: auto 0 1.2rem;
  text-align: left;
}

.iframe-placeholder + .flag-title {
  text-align: left;
  margin: 0;
}

.flag-title h3 {
  margin-bottom: 0;
}

.controls {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls button,
.controls .count {
  margin: 0;
  padding: 0;
  flex: 30%;
}

.controls :first-child {
  text-align: left;
}

.controls :last-child {
  text-align: right;
}
img {
  display: block;
  margin: auto;
}

.zoom-controls {
  position: absolute;
  bottom: 0;
  transform: translate3d(-50%, 120%, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  left: 50%;
  min-width: 100%;
  text-align: center;
}

.zoom-controls > div {
  margin: auto;
}

.zoom-controls h3 {
  padding: 0 1rem;
  margin: 0;
  flex-grow: 1;
}

button.close,
a.play {
  border: 0;
  background: #222;
  border-radius: 50px;
  display: inline-block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  text-decoration: none;
  padding: 0;
  font-size: 10px;
  color: white;
  line-height: 2;
  box-shadow: 1px 1px 3px #999;
  cursor: pointer;
}

a.play {
  vertical-align: bottom;
  background-color: #666;
  box-shadow: none;
}

a.play:hover {
  background-color: #333;
}

button.close {
  position: absolute;
  right: -10px;
  top: -10px;
}

button.close span,
a.play span {
  text-indent: 100px;
}

button.close::before,
a.play::before {
  content: "✕";
  display: block;
}

a.play::before {
  content: "►";
  text-indent: 2px;
}

.map-credits {
  position: absolute;
  right: 1%;
  bottom: 1%;
  font-size: 75%;
  text-align: right;
}

.back dl {
  width: 85%;
  margin: 1rem auto auto;
}

.back dt {
  font-weight: bold;
}

.back dd {
  font-style: italic;
  margin: 0 auto 7px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: relative;
  padding: 3rem 0 0;
}

.spinner {
  transform: translate3d(0, 0, 0);
  border: 11px solid rgba(180, 220, 240, 0.6);
  width: 100px;
  margin: auto;
  padding-top: 100px;
  position: relative;
  border-radius: 100px;
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spinner-small {
  width: 35px;
  padding-top: 35px;
  border-width: 6px;
}

.spinner::before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  background: rgb(130, 180, 200);
  border-radius: 10px;
  position: absolute;
  left: -10px;
  top: 50%;
}

.spinner-small::before {
  width: 6px;
  height: 6px;
  left: -6px;
}

#root > .spinner {
  margin-top: 150px;
}

.select {
  position: relative;
  display: inline-block;
}

@media (min-width: 615px) {
  h1 br {
    display: none;
  }
}

h1 + small {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.select-button {
  display: inline-block;
  cursor: pointer;
  padding: 10px 15px;
  padding-right: 70px;
  border-color: #e6e6e6;
  line-height: 1.2;
}

.select-button::before {
  content: "";
  height: 100%;
  top: 0px;
  width: 50px;
  position: absolute;
  right: 0;
  border-left: 2px solid #e6e6e6;
}

.select-button::after {
  content: "▿";
  display: inline-block;
  width: 52px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  color: #ccc;
  transition: all 0.2s;
}

.select-button:hover,
.select-button[aria-expanded="true"],
.select-button:focus {
  border-color: #ccc;
}

.select-button:hover::before,
.select-button[aria-expanded="true"]::before,
.select-button:focus::after {
  border-color: #ccc;
}

.select-button:hover::after,
.select-button[aria-expanded="true"]::after,
.select-button:focus::after {
  color: #aaa;
}

.select-button[aria-expanded="true"]::after {
  transform: translateY(-50%) rotate(90deg);
  border-left: 0;
}

.select-menu {
  width: 300px;
  min-width: 100%;
  text-align: left;
  background: rgba(255, 255, 255, 0.9);
  font-size: 70%;
  position: absolute;
  right: 100%;
  padding: 0;
  transform: translate3d(100%, 10px, 0);
  z-index: 1500;
}

.select-item {
  padding: 10px 15px 0;
}

.select-item:last-child {
  padding-bottom: 10px;
}

.select-item:hover,
.select-item:focus {
  background: #fff;
}

label {
  display: flex;
}

.github-hotcorner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.ReactModal__Content img {
  max-height: 100%;
  object-fit: contain;
  width: 100%;
}

.banner {
  background: #ffd700;
  padding: 20px;
  margin: 0 calc(-3% - 2px);
  padding: 25px 15px;
  text-decoration: none;
  color: black;
  border-bottom: 2px solid #daa520;
  box-shadow: inset 2px 2px 21px #daa520;
  display: block;
  text-align: center;
}

.banner:hover {
  text-decoration: underline;
}
